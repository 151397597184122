import * as React from 'react'

const NotFoundPage = () => {
  return (
    <main>
      <h1>NotFoundPage</h1>
    </main>
  )
}

export default NotFoundPage
